
import {mapActions, mapMutations, mapState} from "vuex";
import ButtonRegion from "@/components/navigation/Header/ButtonRegion";

  export default {
    name: "MainMenu",
    components: {
      ButtonRegion,
      LeftMenu : () => import("@/components/navigation/Header/LeftMenu"),
      DonationButton: () => import("@/components/navigation/Header/DonationButton"),
      Search: () => import("@/components/search/Search"),
    },
    methods: {
      playLiveStream: function () {
        this.$store.dispatch("audioPlayer/playLiveStream")
        this.closeSubMenu()
      },
      ...mapMutations({
        hideLeftMenu: 'header/hideLeftMenu',
        activateScroll: 'header/activateScroll',
        desactivateScroll: 'header/desactivateScroll',
        setSubMenuVisibility: 'header/setSubMenuVisibility',
        showModal: 'modal/showModal',
      }),
      ...mapActions({
        handleSubMenu: 'header/handleSubMenu',
        handleSearchBar: 'header/handleSearchBar',
        handleLeftMenu: 'header/handleLeftMenu',
        closeSubMenu: 'header/closeSubMenu'
      }),
    },
    computed: {
      ...mapState('header', {
        displayLeftMenu: state => state.displayLeftMenu,
        scroll: state => state.scroll,
        slimHeader: state => state.slimHeader,
        evenmt: state => state.event
      }),
    },
  }
