
import { mapMutations, mapState } from "vuex";

export default {
  name: "ButtonRegion",
  props: ['mobileHeader'],
  computed: {
    ...mapState({
      radioName: (state) => {
        return state.radio.radioName
      }
    }),
    region() {
      return this.radioName ?? 'MA REGION'
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/showModal',
    }),
    decodeRadioName(name) {
      if (process.client) {
        const doc = new DOMParser().parseFromString(name, "text/html");
        return doc.documentElement.textContent;
      }
      return name;
    }
  }
};
